export const currencies = {
  Niger: {
    currency: "CFA franc",
    symbol: "₣",
    code: "XAF",
  },
  Tanzania: {
    currency: "Tanzanian shilling",
    symbol: "Sh",
    code: "TZS",
  },
  Rwanda: {
    currency: "Rwandan franc",
    symbol: "₣",
    code: "RWF",
  },
  Zambia: {
    currency: "Zambian kwacha",
    symbol: "ZK",
    code: "ZMW",
  },
  Honduras: {
    currency: "Honduran lempira",
    symbol: "L",
    code: "HNL",
  },
  Nigeria: {
    currency: "Nigerian naira",
    symbol: "₦",
    code: "NGN",
  },
  Lebanon: {
    currency: "Lebanese Pound",
    symbol: "LL",
    usd_symbol: "$",
    lbp_symbol: "LL",
    code: "LBP",
  },
  /*Retained for backward compatibility as there could 
    be instances referencing Mozambique in country object. */
  Mozambique: {
    currency: "Mozambican metical",
    symbol: "MT",
    code: "MZN",
  },
  Moçambique: {
    currency: "Mozambican metical",
    symbol: "MT",
    code: "MZN",
  },
  Ghana: {
    currency: "Ghanaian cedi",
    symbol: "GH₵",
    code: "GHS",
  },
  Kenya: {
    currency: "Kenyan shilling",
    symbol: "KES",
    code: "KES",
  },
}

export const getCurrency = (country) => currencies[country]
